export const PROFILE_UPDATE_MENTEE = "/api/auth/mentee/profile";
export const SIGNUP = "/api/sign-up";
export const SIGNIN = "api/auth/login";
export const LOGIN_OTP_VERIFICATION = "/api/auth/passcode";
export const OTP_VERIFICATION = "/api/verify-user";
export const SESSION_COMPLETED_LIST = "/api/auth/mentee/completed-session/list";
export const MENTOR_LIST = "/api/auth/mentee/search-mentor";
export const AVAILABEL_SLOTS = "/api/auth/mentee/available-slots";
export const LANGUAGES = "api/languages";
export const STATES = "api/states";
export const PROFILE_UPDATE_MENTOR = "/api/auth/mentor/profile";
export const INDUSTRIES = "api/industries";
export const SPECIALITIES = "api/get/sub-categories";
export const VUE_APP_API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
export const MENTOR_DETAIL_PAGE = "/api/user-details";
export const CREATE_BOOKING = "/api/auth/mentee/session/booking/payment/confirm";
export const BookingTimeSlotsStep = 0;
export const PhoneNumberVerify = 1;
export const QuestionStep = 2;
export const CardSteps = 3;
export const BillingStep = 4;
export const FinalMessageStep = 5;
export const RoleSelectionSecreen = 0;
export const SIGNUPINFORMATION = 1;
export const OTPSreenSignup = 2;
export const MenteeProfilePage = 3;
export const MentorProfilePage = 4;
export const MentorSuccesPage = 5;
export const SigninNumberScreen = 1;
export const OTPSreenSignin = 2;

export default {
  VUE_APP_API_BASE_URL: VUE_APP_API_BASE_URL,
  PROFILE_UPDATE_MENTEE: PROFILE_UPDATE_MENTEE,
  SIGNUP: SIGNUP,
  OTP_VERIFICATION: OTP_VERIFICATION,
  SESSION_COMPLETED_LIST: SESSION_COMPLETED_LIST,
  MENTOR_LIST: MENTOR_LIST,
  AVAILABEL_SLOTS: AVAILABEL_SLOTS,
  MENTOR_DETAIL_PAGE: MENTOR_DETAIL_PAGE,
  BookingTimeSlotsStep: BookingTimeSlotsStep,
  CardSteps: CardSteps,
  QuestionStep: QuestionStep,
  PhoneNumberVerify: PhoneNumberVerify,
  BillingStep: BillingStep,
  CREATE_BOOKING: CREATE_BOOKING,
  FinalMessageStep: FinalMessageStep,
  SIGNUPINFORMATION: SIGNUPINFORMATION,
  RoleSelectionSecreen: RoleSelectionSecreen,
  OTPSreenSignup: OTPSreenSignup,
  MenteeProfilePage: MenteeProfilePage,
  MentorProfilePage: MentorProfilePage,
  MentorSuccesPage: MentorSuccesPage,
  OTPSreenSignin: OTPSreenSignin,
  SigninNumberScreen: SigninNumberScreen,
};
