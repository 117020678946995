import { createStore } from 'vuex'

import UserModule from './user.module.js'
import ModalStates from './modal.module.js'
import tempData from './temp.module.js'
import mentorData from './mentor.module.js'
import createPersistedState from 'vuex-persistedstate';

export default createStore({
    modules: {
        userModule: UserModule,
        modalStates: ModalStates,
        tempData:tempData,
        mentorData:mentorData
    },
  plugins: [createPersistedState({
    paths: ["userModule"]
  })],
})
