
export default {
    namespaced: true,
    state: {
        tempUser: {
            user:{},
            image:null
        },
        mentordetail: '',
        roleId: ''
    },
    getters: {
    },
    mutations: {
        setTempUser (state, {user,preview}) {
            state.tempUser.user = user
            state.tempUser.image = preview?.value
        },
        setMentordetail (state, data) {
            state.mentordetail = data
        },
        setUserRole (state, role) {
            state.roleId = role
        }
    }
}