export default {
    namespaced: true,
    state: {
        signupmodal: false,
        BookNowModal: false,
        MentorDetailModal: false,
        signinmodal: false,
        afterLogin: false,
        signupStep: 0,
        signinStep: 1,
        LoginSteps: false,
        signupStepPrevious: 0
    },
    mutations: {
        setSignupModal: function (state) {
             state.signupmodal = !state.signupmodal;
             state.signinmodal = false;
        },
        setBookNowModal: function (state) {
                return state.BookNowModal = !state.BookNowModal;
        },
        setMentorDetailModal: function (state) {
                return state.MentorDetailModal = !state.MentorDetailModal;
        },
        setSigninModal: function (state) {
            state.signupmodal = false;
            return state.signinmodal = !state.signinmodal;
        },
        setAfterLogin (state) {
            state.afterLogin = true
        },
        setLoginSteps (state) {
            state.LoginSteps = true
        },
        setSignupStep (state,data) {
            state.signupStep = data
        },
        setSignupPreviousStep (state,data) {
            state.signupStepPrevious = data
        },
        setSigninStep (state,data) {
            state.signinStep = data
        }
    }
}