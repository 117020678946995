<template>

    <router-view/>
</template>

<script>
export default {
};
</script>

<style>
@import './assets/css/style.css';
@import 'style.css';
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
}

nav {
    padding: 30px;
}

nav a {
    font-weight: bold;
    color: #2c3e50;
}

nav a.router-link-exact-active {
    color: #42b983;
}

.member-login {
    border: 3px solid #24aae1;
}

.md-buttons a {
    background-color: #24aae1;
    color: #fff;
}

.aa-right img {
    float: right;
    width: 90%;
}

.app-download-links a {
    background-color: #24aae1;
    color: #fff;
}
 

.modal-form-btn {
    background-color: black;
    border: 2px solid #24aae1;
    color: #fff;
}
</style>
