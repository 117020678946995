import axios from 'axios'
import {VUE_APP_API_BASE_URL} from '../constants'

axios.defaults.baseURL = VUE_APP_API_BASE_URL;

export async function getApi(url, data = [], authenticated = false, store = {}) {
    let config
    if (authenticated) {
        if (store.getters["userModule/isAuthenticated"]) {
            config = {
                headers: {'Authorization': 'Bearer ' + store.state.userModule.token},
                params: data
            }
        } else {
            return false
        }
    } else {
        config = {
            params: data,
        }
    }
    const datas = axios.get(url, config)
        .then(response => {
                return response.data
            }
        )
        .catch(error => {
            return error.response.data;
        })
    return datas
}

export async function postApi(url, data = [], authenticated = false, store = []) {
    let config
    let header
    if (authenticated) {
        if (store.getters["userModule/isAuthenticated"]) {
            header = {
                headers: {
                    'Authorization': 'Bearer ' + store.state.userModule.token,
                    // 'Authorization': 'Bearer ' + this.$store.state.token,
                    "Content-Type": "multipart/form-data"
                },
            }
        } else {
            return false
        }
    } else {
        header = {
            headers: {
                "Content-Type": "multipart/form-data"
            },
        }
    }

    config = data
    return axios.post(url, config, header)
        .then(res => {
                return res.data
            }
        )
        .catch(error => {
            return error.response.data;
        })
}