import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: "/",
    component: () => import("../layouts/default"),
    children: [
      {
        path: "/abount",
        name: "HomeComponent",
        component: () => import("../views/HomeView"),
      },
      {
        path: "/",
        name: "MentorListPage",
        component: () => import("../views/MentorList"),
      },
      {
        path: "/profile-detail",
        name: "ProfileDetail",
        component: () => import("../views/ProfileDetail"),
      },
      {
        path: "/review",
        name: "ReviewPage",
        component: () => import("../views/Review"),
      },
      {
        path: "/fake",
        name: "FakeFile",
        component: () => import("../views/FakeFile"),
      },
      {
        path: "/mul",
        name: "MUl",
        component: () => import("../components/MultiSelectComponent"),
      },
    ],
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
