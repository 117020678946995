import {MenteeProfilePage, MentorProfilePage, MentorSuccesPage} from "@/constants";
import store  from './index'
export default {
    namespaced: true,
    state: {
        user: {},
        userdetails: {},
        token: '',
        isapproved: false
    },
    getters: {
        getToken: function (state) {
            return state.token;
        },
        isAuthenticated: function (state) {
            if(state.token!=="") {
                return true;
            }else {
                return false
            }
        },
        isAllowedUser: function (state) {
            if(state.token!=="") {
                return true;
            }else {
                return false
            }
        }
    },
    mutations: {
        setToken (state, token) {
            state.token = token
        },
        setUser (state, user) {
            state.user = user
        },
        setUserDetail (state, user) {
            state.userdetails = user
        },
        setMentorApprove (state, check) {
            state.isapproved = check
        },
        setIsAllowed(state){
            if(state.user.roles[0]?.name == 'mentee'){
                store.commit("modalStates/setSignupStep",MenteeProfilePage)
            }else{
                if(state.user.is_approved == 0 && state.userdetails.mentor_payment_method != null){
                    store.commit("modalStates/setSignupStep",MentorSuccesPage)
                 }else{
                    store.commit("modalStates/setSignupStep",MentorProfilePage)
                }
            }
        }
    }
}