import {postApi} from "@/service/RequestApi";
import {AVAILABEL_SLOTS, CREATE_BOOKING, MENTOR_DETAIL_PAGE} from "@/constants";
import store from './index'
export default {
    namespaced: true,
    state: {
        mentordetail: {},
        mentorTiming: {},
        menteebooking: {
            date:{},
            BookingQuestion:{},
            cardUser:{},
            billingDetail:{},
            step:0,
            success:'',
            error:'',
        },
    },
    getters: {
        getToken: function (state) {
            return state.token;
        },
    },
    actions: {
        async getMentorDetail({commit}) {
                let ApiData = {
                    id: store.state.tempData.mentordetail?.id,
                };
                const response = await postApi(MENTOR_DETAIL_PAGE, ApiData, false);
                if (response.success) {
                    commit('setMentorDetail',response.data)
                } else {
                    commit('setMentorDetail',false)
                }
        },
        async getMentorTiming({commit}) {
            let ApiData = {
                user_id: store.state.tempData.mentordetail?.id,
            };
            const response = await postApi(AVAILABEL_SLOTS, ApiData, true, store);
            if (response.success) {
                commit('setMentorTiming',response.data)
            } else {
                commit('setMentorTiming',false)
            }
        },

        async createBooking({commit,state}) {
            let ApiData = {
                card_no: state.menteebooking.cardUser?.card_no,
                expiry_month: state.menteebooking.cardUser?.expiry_month,
                expiry_year: state.menteebooking.cardUser?.expiry_year,
                cvv: state.menteebooking.cardUser?.cvv,
                mentor_id: store.state.tempData.mentordetail?.id,
                booking_date: state.menteebooking.date?.date,
                booking_time_from: state.menteebooking.date?.time,
                address: state.menteebooking.billingDetail?.address,
                state: state.menteebooking.billingDetail?.state,
                zip_code: state.menteebooking.billingDetail?.zip_code,
                question: state.menteebooking.BookingQuestion,
            };
            console.log(ApiData)
            const response = await postApi(CREATE_BOOKING, ApiData, true, store);
            if (response.success) {
                commit('setSuccess',response.message)
            } else {
                commit('setError',response.message)
            }
        },

    },
    mutations: {
        setToken(state, token) {
            state.token = token
        },
        setMentorDetail(state, data) {
            state.mentordetail = data
        },
        setMentorTiming(state, data) {
            state.mentorTiming = data
        },
        setBookingDate(state, data) {
            state.menteebooking.date = data
        },
        setBookingStep(state, data) {
            state.menteebooking.step = data
        },
        setBookingQuestion(state, data) {
            var question=[];
            Object.keys(data).forEach(key => {
                question.push(data[key]);
            });
            state.menteebooking.BookingQuestion = question
        },
        setcardUser(state, data) {
            state.menteebooking.cardUser = data
        },
        setBillingDetail(state, data) {
            state.menteebooking.billingDetail = data
        },
        setSuccess(state, data) {
            state.menteebooking.success = data
        },
        setError(state, data) {
            state.menteebooking.error = data
        },
        resetMentorBooking(state) {
            state.menteebooking.date = {}
            state.menteebooking.step = 0
            state.menteebooking.BookingQuestion = {}
            state.menteebooking.cardUser = {}
            state.menteebooking.billingDetail = {}
            state.menteebooking.success = ''
            state.menteebooking.error = ''
        },
    }
}